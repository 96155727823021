import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { NextLink } from 'components/NextLink';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { RelatedDataService } from 'service/relatedData/relatedDataService';
import { TrackingService } from 'service/tracking/trackingService';
import styled from 'styled-components';
import type { RecipeResult, TipsAndTricksDetail } from 'types/Recipes';
import type { ICardItem, ISuggestedData } from '../Cards/types';

const Card = dynamic(() => import('components/Cards').then((m: any) => m.Card), {
  ssr: true,
}) as any;

const Slider = dynamic(() => import('components/SliderVanilla').then((m: any) => m.Slider), {
  ssr: true,
}) as any;

const Title = dynamic(() => import('components/Typography').then((m: any) => m.Title), {
  ssr: true,
}) as any;

const Text = dynamic(() => import('components/Typography').then((m: any) => m.Text), {
  ssr: true,
}) as any;

const Grid = dynamic(() => import('components/Grid').then((m: any) => m.Grid), {
  ssr: true,
}) as any;

const GridItem = dynamic(() => import('components/Grid').then((m: any) => m.GridItem), {
  ssr: true,
}) as any;

const GridRow = dynamic(() => import('components/Grid').then((m: any) => m.GridRow), {
  ssr: true,
}) as any;

const HeaderWrapper = styled.div`
  text-align: center;
`;
const SubHeading = styled.div`
  margin-bottom: 20px;
`;
const Heading = styled.div`
  margin-bottom: 40px;
`;

const CarouselCard = styled.div`
  margin-right: 25px;
  min-width: 77%;
`;

const clickTeaserEvent =
  (heading: string, containerPos: number, copy: any) => (recipeData: any, position?: number) => {
    TrackingService.clickTeaserSeeRecipe({
      teaserName: recipeData?.title,
      containerTitle: heading,
      containerPosition: containerPos,
      containerType: 'grid',
      teaserPosition: position || 0,
      teaserRecipeId: recipeData?.id,
      teaserRecipeTitle: recipeData?.title,
      teaserRecipeDifficulty: copy[recipeData?.information?.difficulty] || 'na',
      teaserRecipeRating: recipeData?.information?.rating,
      teaserRecipeDuration: recipeData?.information?.totalTime,
    });
  };

const RecipeGridLogic = ({
  recipeData,
  currentRecipe,
  copy,
  heading,
}: {
  recipeData: ISuggestedData[];
  currentRecipe: RecipeResult | TipsAndTricksDetail;
  copy: any;
  heading: string;
}) => {
  const firstArr: any = [];
  const secondArr: any = [];
  let containerPosition = recipeData.length;
  if (recipeData.length <= 5 && recipeData.length > 2) {
    for (let i = 0; i <= recipeData.length - 2; i += 1) {
      firstArr.push(recipeData[i]);
      containerPosition = i + 1;
    }
    secondArr.push(recipeData[recipeData.length - 1]);
    const clickTeaserSeeRecipeGrid = clickTeaserEvent(heading, containerPosition, copy);
    return (
      <GridRow columns={16}>
        <GridItem colSpan={8} key={`${currentRecipe.Id}-left-col`}>
          <GridRow columns={16}>
            {firstArr.map((recipe: ISuggestedData, index: number) => (
              <GridItem key={recipe.id} colSpan={firstArr.length <= 3 && index === 2 ? 16 : 8}>
                <Card
                  data={recipe as ICardItem}
                  copyDictionary={copy}
                  height="500px"
                  showInfo
                  cardType={firstArr.length <= 3 && index === 2 ? undefined : 'mediumSmall'}
                  LinkComponent="a"
                  link={recipe.link}
                  gridType={firstArr.length <= 3 && index === 2 ? 'wide' : 'regular'}
                  trackingEvent={clickTeaserSeeRecipeGrid}
                />
              </GridItem>
            ))}
          </GridRow>
        </GridItem>
        <GridItem colSpan={8} key={`${currentRecipe.Id}-right-col`}>
          {secondArr.map((recipe: ISuggestedData) => (
            <Card
              key={recipe.id}
              data={recipe as ICardItem}
              copyDictionary={copy}
              height={recipeData.length === 3 ? '500px' : '1019px'}
              showInfo
              link={recipe.link}
              LinkComponent={NextLink}
              gridType={recipeData.length === 3 ? 'regular' : 'large'}
              trackingEvent={clickTeaserSeeRecipeGrid}
            />
          ))}
        </GridItem>
      </GridRow>
    );
  }
  return (
    <GridRow columns={16}>
      {recipeData?.map((recipe: ISuggestedData, index: number) => {
        const containerPos = index + 1;
        const clickTeaserSeeRecipeGrid = clickTeaserEvent(heading, containerPos, copy);
        return (
          <GridItem colSpan={index === 2 ? 8 : 4} key={`${recipe.id}-main`}>
            <Card
              data={recipe as ICardItem}
              copyDictionary={copy}
              height="500px"
              showInfo
              link={recipe.link}
              cardType={index !== 2 ? 'mediumSmall' : undefined}
              LinkComponent="a"
              gridType={index !== 2 ? 'regular' : 'wide'}
              trackingEvent={clickTeaserSeeRecipeGrid}
            />
          </GridItem>
        );
      })}
    </GridRow>
  );
};

const RelatedRecipeComp = ({
  currentRecipe,
  linkName = '',
  relatedRecipe,
  copy,
  subTitle,
  title,
}: {
  currentRecipe: RecipeResult | TipsAndTricksDetail;
  linkName: string;
  relatedRecipe: any[];
  copy: any;
  subTitle: string;
  title: string;
}) => {
  const { pathTranslations } = useGlobalContext();
  const [recipeData, setRecipeData] = useState<ISuggestedData[]>([]);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [sliderSettings, setSliderSettings] = useState<{ [name: string]: any }>({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
  });
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  /**
   * Maps the response from `getRecipesRatings` back into the correct recipe object
   * finalized the flow of structure the recipeData array
   * @param ratings Recipes ratings array returned from `getRecipesRatings`
   * @param recipes array of recipes that will be used as related recipes
   */
  const mapRelatedData = (recipes: any[]) => {
    /**
     * Formatted recipe data with rating
     */
    const finalRecipeData: ISuggestedData[] = RelatedDataService.getRelatedData(
      recipes,
      pathTranslations,
      'recipe',
      linkName,
    );

    setRecipeData(finalRecipeData);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    // setting lazy loading on the client on mount so that the items are rendered
    // when JS is disabled.
    setSliderSettings({
      ...sliderSettings,
      lazyLoad: 'ondemand',
    });

    // get related recipes ratings and finalize the recipeData array
    /**
     * Array of recipe ids to be used to fetch their ratings in a batch request
     */
    const ids: string[] = [];
    const recipes: any[] = relatedRecipe.reduce((recipesArr, recipe) => {
      if (recipe && recipe.Id && recipe.Id !== currentRecipe.Id) {
        recipesArr.push(recipe);
        ids.push(recipe.Id);
      }
      return recipesArr;
    }, []);

    mapRelatedData(recipes);

    return () => {
      window.removeEventListener('resize', screenSize);
    };
  }, [currentRecipe.Id]);
  const getStatiRecipes = () => {
    const recipesStatic: any[] = relatedRecipe?.reduce((recipesArr, recipe) => {
      if (currentRecipe && recipe.Id !== currentRecipe.Id) {
        recipesArr.push(recipe);
      }
      return recipesArr;
    }, []);
    const finalRecipeData = RelatedDataService.getRelatedData(
      recipesStatic,
      pathTranslations,
      'recipe',
      linkName,
    );
    return (
      <Grid>
        <RecipeGridLogic
          recipeData={finalRecipeData}
          currentRecipe={currentRecipe}
          copy={copy}
          heading={subTitle}
        />
      </Grid>
    );
  };
  return (
    <div data-test="related-recipes" className="overflow-hidden" data-print="hide">
      <HeaderWrapper>
        <SubHeading>
          <Text tag="p" type="lead">
            {subTitle}
          </Text>
        </SubHeading>
        <Heading>
          <Title tag="h2" type="md">
            {title}
          </Title>
        </Heading>
      </HeaderWrapper>
      {/* Backend cards rendering, due to SEO requirements */}
      {screenWidth === 0 ? (
        getStatiRecipes()
      ) : (
        <>
          {screenWidth <= 767 ? (
            <Slider {...sliderSettings} className="mb-14 pb-5 pl-4 ml-small">
              {recipeData?.map((recipe: ISuggestedData, index: number) => {
                const containerPos = index + 1;
                const clickTeaserSeeRecipeGrid = clickTeaserEvent(subTitle, containerPos, copy);
                return (
                  <CarouselCard key={recipe.id ?? `recipe-card-slider-${index}`}>
                    <Card
                      data={recipe as ICardItem}
                      copyDictionary={copy}
                      showInfo
                      height="500px"
                      cardType="mediumSmall"
                      LinkComponent={NextLink}
                      link={recipe.link}
                      trackingEvent={clickTeaserSeeRecipeGrid}
                    />
                  </CarouselCard>
                );
              })}
            </Slider>
          ) : (
            <Grid>
              <RecipeGridLogic
                recipeData={recipeData}
                currentRecipe={currentRecipe}
                copy={copy}
                heading={subTitle}
              />
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default RelatedRecipeComp;
