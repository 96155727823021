import { useTimeout } from '@/hooks/useTimeout';
import { useEffect, useState } from 'react';
import { TrackingService } from '@/service/tracking/trackingService';
import { usePathname } from 'next/navigation';

type Config = {
  timeOut: number;
  scrollPercentage: number;
  eventCb: () => void;
};

export const useTimerScrollTracking = (config: Config) => {
  const pathname = usePathname();
  const [inView, setInView] = useState(false);
  const [tracked, setTracked] = useState(false);

  const [timerUp, setTimerUp] = useState(false);
  useTimeout(config.timeOut, () => setTimerUp(true));

  // Make sure to reset the state when the pathname changes
  useEffect(() => {
    setInView(false);
    setTracked(false);
    setTimerUp(false);
  }, [pathname]);

  // Observe the pixel and set state when it's in view
  useEffect(() => {
    const marker = TrackingService.createTrackingMarker(`${config.scrollPercentage}%`);

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0 },
    );

    if (inView) {
      observer.disconnect();
      return;
    }

    observer.observe(marker);

    return () => {
      observer.disconnect();
      document.body.removeChild(marker);
    };
  }, [config.scrollPercentage, inView, pathname]);

  // Trigger the tracking event only if both conditions are met
  // (timer is up and element is in view)
  useEffect(() => {
    if (timerUp && inView && !tracked) {
      config.eventCb();
      setTracked(true);
    }
  }, [timerUp, inView, config.eventCb, config, tracked]);
};
